import styled from "styled-components";
import { StyledSection as StyledTemplateSection } from "templates/projects/styles";

type Props = {
  projectColor?: string;
  skillColor?: string;
}

export const StyledSection = StyledTemplateSection;

export const SectionHeader = styled.div`
  width: 100%;
  min-height: 90vh;
  display: grid;
  padding: 1.5rem 2.5rem;
  background-color: #EEF0F0;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:after {
      content: "";
      height: 2.4rem;
      display: block;
      width: 2.4rem;
    }
  }

  .app-logo {
    height: 2.4rem !important;
    background-color: var(--yellow) !important;
  }

  .link-btn {
    display: flex;
    font-weight: 600;
    font-size: .7rem;
    align-items: center;
    color: var(--border-dark-color);
    .btn-icon { font-size: 1.1rem; margin-right: .5rem; }
  }

  @media only screen and (max-width: 720px) {
    min-height: 95vh;
    padding: 1.25rem;
    .link-btn {
      display: none;
    }
  }
`;

export const SectionWrapper = styled.div`
  max-width: 60rem !important;
  margin: 3.5rem auto !important;
  background-color: var(--white);

  &.header-wrapper {
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-template-columns: auto 1fr;
    background-color: transparent;
    gap: 4.5rem;
  }

  .section-heading {
    width: 23.5rem;
    .title {
      line-height: 1.2 !important;
      text-transform: capitalize;
    }
  }

  @media only screen and (max-width: 1024px) {
    max-width: 30rem !important;
    &.header-wrapper {
      padding: 0 0.75rem;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      gap: 4.5rem;
    }
    .section-heading {
      width: 100%;
      .description {
        line-height: 1.85 !important;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    &.header-wrapper {
      gap: 2.5rem;
    }
  }
`;

export const ProjectCoverImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props: Props) => props.projectColor};

  &.gallery-wrapper {
    padding: 1rem;
    cursor: pointer;
    position: relative;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius-1);
    background-color: var(--gray);
    &:hover {
      .overlay {
        display: flex;
      }
    }
  }

  &.modal-wrapper {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    background-color: rgba(60, 57, 74, 0.9);
    z-index: var(--z-index-6);
    .close-btn {
      top: 1.5rem;
      right: 2.5rem;
      position: fixed;
      display: flex;
      justify-content: center;
      background-color: var(--white);
      align-items: center;
      height: 2.4rem;
      width: 2.4rem;
    }
  }

  .cover-image {
    width: 30rem !important;
  }

  .gallery-image {
    height: 18rem !important;
  }

  .modal-image {
    height: 80vh !important;
  }

  @media only screen and (max-width: 1024px) {
    &.gallery-wrapper {
      border-radius: 0;
    }
    &.modal-wrapper {
      padding: 0 2rem;
      .close-btn {
        top: 1.25rem;
        right: 1.25rem;
      }
    }
    .cover-image {
      width: 100% !important;
      height: 17rem !important;
    }
    .gallery-image {
      height: 15rem !important;
    }
  }

  @media only screen and (max-width: 720px) {
    .cover-image {
      width: 100% !important;
      height: 12rem !important;
  }
`;

export const ProjectDescription = styled.div`
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
  padding: 3rem 0;
  line-height: 1.5;
  font-size: 1rem;
  color: #696969;
`;

export const SectionContent = styled.div`
  display: grid;
  padding: 5rem 2rem;

  .section-wrapper {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
    max-width: 60rem !important;
  }

  .overlay {
    top 0;
    left 0;
    width: 100%;
    height: 100%;
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    background: rgba(17, 16, 41, 0.3);
    transition: all 0.3s ease-in-out;
    .btn-icon {
      font-size: 1.7rem;
      color: var(--white);
    }
  }

  .card {
    padding: 1.25rem 1.5rem;
    background-color: var(--white);
    border: 1px solid var(--border-color);

    &.gallery { padding: 0.75rem }

    .heading {
      margin: 0;
      font-size: 0.7rem;
      color: var(--blue);
      letter-spacing: 0.01rem;
      text-transform: uppercase;
    }
  }

  .project-metadata {
    gap: 1.5rem;
    width: 19rem;
    display: flex;
    flex-direction: column;
  }

  .gallery {
    gap: 0.75rem;
    display: grid;
    grid-template-columns: 1fr;
  }

  .project-tags {
    gap: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 2rem;
    .heading {
      width: 100%;
      margin-bottom: 0.25rem;
      &.mid-content {
        margin-top: 1.5rem;
      }
    }
    .tag {
      cursor: pointer;
      font-weight: 600;
      font-size: 0.65rem;
      padding: .15rem .5rem;
      letter-spacing: 0.02rem;
      background-color: var(--gray);
      text-transform: uppercase;
    }
    .tool {
      cursor: pointer;
      display: flex;
      font-weight: 600;
      font-size: 0.65rem;
      align-items: stretch;
      padding: 0;

      .tool-image {
        width: 1.25rem !important;
        height: 1.25rem !important;
        &.icon {
          font-size: 1rem;
          width: 1rem !important;
        }
      }

      .tool-name {
        display: flex;
        padding: 0 0.75rem;
        align-items: center;
        background-color: var(--white);
      }

    }
  }

  @media only screen and (max-width: 1024px) {
    padding: 5rem 5rem 1rem;

    .section-wrapper {
      gap: 3.5rem;
      grid-template-columns: 1fr;
      max-width: 30rem !important;
    }

    .project-metadata {
      width: 100%;
    }

    .card {
      &.gallery {
        padding: 0rem;
        background-color: transparent;
        border: 0;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    padding: 3.5rem 2rem;
  }
`;

export const ToolTag = styled.div`
  border: 1px solid transparent;
  background-color: ${(props: Props) => props.skillColor}10;
  border-color: ${(props: Props) => props.skillColor}80;

  .img-wrapper {
    display: flex;
    padding: .25rem .5rem;
    color: ${(props: Props) => props.skillColor}70;
    border-right: 1px solid ${(props: Props) => props.skillColor}70;
    justify-content: center;
    align-items: center;
  }
`;
