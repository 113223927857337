import { AppLogo, Head, Image, Main, NavMenu } from 'components'
import { ArrowLeftIcon, CloseIcon, EyeIcon, HashtagIcon } from 'components/common/icons'
import StyledProjectLinks from 'components/project/links'
import { Link, PageProps } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { useMemo, useState } from 'react'
import { Project } from 'utils/types'
import Footer from 'views/footer'
import { ProjectCoverImage, SectionContent, SectionHeader, SectionWrapper, StyledSection, ToolTag } from './styles'

interface TemplateProps extends PageProps {
  pageContext: { project: Project }
}

const ProjectDetailsTemplate: React.FC<TemplateProps> = ({ pageContext }) => {

  const [currentImage, setCurrentImage] = useState<IGatsbyImageData>(null);

  const { project, metadata } = useMemo(() => {
    const data = pageContext.project;
    const tags = data.tags.concat(data.skills.map(skill => skill.name));
    return { project: data, metadata: [...new Set(tags)] };
  }, [pageContext]);

  return (
    <>
      <Head
        title={project.title.toUpperCase()}
        description={project.description}
        image={project.coverImage.images.fallback.src}
        tags={metadata}
      />
      <Main>
        <NavMenu />
        <StyledSection id='project-details'>

          <SectionHeader>

            <div className="heading">
              <AppLogo />
              <Link to='/projects/' className='link-btn'>
                <ArrowLeftIcon className='btn-icon' />
                <span>Back to Projects</span>
              </Link>
            </div>

            <SectionWrapper className="section-wrapper header-wrapper">

              <div className="section-heading">
                <h5 className="caption">PROJECT DETAILS</h5>
                <h1 className="title project-title">{project.title}</h1>
                <p className="description">{project.description}</p>
                <div className="project-links">
                  <StyledProjectLinks
                    data={project.links}
                    projectType={project.type}
                  />
                </div>
              </div>

              <ProjectCoverImage className="image-wrapper" projectColor={project.color}>
                <Image src={project.coverImage} className='cover-image' />
              </ProjectCoverImage>

            </SectionWrapper>

          </SectionHeader>

          <SectionContent className="section-content">
            <div className='section-wrapper'>

              <div className="gallery card">
                {
                  project.images.map((image, i) => (
                    <ProjectCoverImage key={i} className="gallery-wrapper" projectColor={project.color}>
                      <Image src={image} className='gallery-image' />
                      <div className="overlay" onClick={() => setCurrentImage(image)}>
                        <EyeIcon className="btn-icon" />
                      </div>
                    </ProjectCoverImage>
                  ))
                }
              </div>

              <div className="project-metadata">

                <div className="card project-tags">
                  <b className='heading'>PROJECT TAGS</b>
                  {
                    project.tags.map(tag => (
                      <ToolTag key={tag} className='tool' skillColor="#473F9A">
                        <div className="img-wrapper">
                          <HashtagIcon className='tool-image icon' />
                        </div>
                        <span className='tool-name'>{tag.toUpperCase()}</span>
                      </ToolTag>
                    ))
                  }
                  <b className='heading mid-content'>TOOLS USED</b>
                  {
                    project.skills.map(skill => (
                      <ToolTag key={skill.id} className='tool' skillColor={skill.color}>
                        <div className="img-wrapper">
                          <Image src={skill.image} className='tool-image' />
                        </div>
                        <span className='tool-name'>{skill.name.toUpperCase()}</span>
                      </ToolTag>
                    ))
                  }
                </div>

              </div>
            </div>
          </SectionContent>

          <Footer />
        </StyledSection>
        {
          currentImage && (
            <ProjectCoverImage className="modal-wrapper" projectColor={project.color}>
              <Image src={currentImage} className='modal-image' />
              <button className="close-btn" onClick={() => setCurrentImage(null)}>
                <CloseIcon className="btn-icon" />
              </button>
            </ProjectCoverImage>
          )
        }
      </Main>
    </>
  )
}

export default ProjectDetailsTemplate
